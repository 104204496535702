


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../../../Components/LoaderComponent';
import './RecentLuckyDraws.css';
import { BASE_URL } from '../../../../Services';

const RecentLuckyDraws = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const [videosPerRow, setVideosPerRow] = useState(3); 

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/videos`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                if (response.data.status === 200 && Array.isArray(response.data.videos)) {
                    setVideos(response.data.videos);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching videos:', error);
                setLoading(false);
            }
        };

        fetchVideos();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setVideosPerRow(1); 
            } else {
                setVideosPerRow(3); 
            }
        };

       
        handleResize();

       
        window.addEventListener('resize', handleResize);

       
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (loading) return <Loader />;

    const extractYouTubeID = (url) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const handleThumbnailClick = (index) => {
        setActiveIndex(index);
    };

    const groupItems = (items, groupSize) => {
        const result = [];
        for (let i = 0; i < items.length; i += groupSize) {
            result.push(items.slice(i, i + groupSize));
        }
        return result;
    };

    const videoGroups = groupItems(videos, videosPerRow); 

    return (
        <section className="image-with-content py-5 video-section ">
            <div className="container-fluid col-lg-10 col-md-11 col-11 recent-lucky-draw-section ">
                <div className="row align-items-center video-bg hide-on-mobile ">
                    <div className="col-lg-12 content-sec-1">
                        <h4 className="title-style mb-3 text-center">Recent Lucky Draws</h4>
                    </div>
                    <div className="col-lg-12 mt-5">
                        <div className="video-container p-3 transparent-background">
                            {videos.length > 0 && (
                                <div className="video-embed p-2 ">
                                    {extractYouTubeID(videos[activeIndex].video_url) ? (
                                        <iframe
                                        className='youtube-video'
                                            style={{ borderRadius: '20px', }}
                                           
                                        
                                            src={`https://www.youtube.com/embed/${extractYouTubeID(videos[activeIndex].video_url)}`}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    ) : videos[activeIndex].video ? (
                                        <video
                                            className="w-50"
                                            controls
                                            style={{ height: 'auto' }}
                                            poster={videos[activeIndex].thumbnail}
                                        >
                                            <source src={videos[activeIndex].video} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <p>No video available</p>
                                    )}
                                </div>
                            )}
                            <div className="col-lg-12 mt-3">
                                <div id="thumbnailCarousel" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        {videoGroups.map((group, index) => (
                                            <div
                                                key={index}
                                                className={`carousel-item ${index === 0 ? 'active' : ''}`}
                                            >
                                                <div className="row">
                                                    {group.map((video, subIndex) => (
                                                        <div key={subIndex} className="col-md-4 col-12">
                                                            <div
                                                                className={`thumbnail-item ${video === videos[activeIndex] ? 'active' : ''}`}
                                                                onClick={() => handleThumbnailClick(videos.indexOf(video))}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <img
                                                                    src={video.thumbnail || 'default-thumbnail.jpg'}
                                                                    alt={`Thumbnail ${subIndex + 1}`}
                                                                    className="focus-glow youtube-thumbnail"
                                                                    style={{ width: '100%', objectFit: 'cover', borderRadius: '20px' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <a
                                        style={{ marginLeft: '-40px' }}
                                        className="carousel-control-prev"
                                        href="#thumbnailCarousel"
                                        role="button"
                                        data-slide="prev"
                                    >
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                    <a
                                        style={{ marginRight: '-40px' }}
                                        className="carousel-control-next"
                                        href="#thumbnailCarousel"
                                        role="button"
                                        data-slide="next"
                                    >
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RecentLuckyDraws;
