



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../sidebar/Header';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';

const ParticipantsList = () => {
    const [participants, setParticipants] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [rewardFilterText, setRewardFilterText] = useState('');
    const [answerFilterText, setAnswerFilterText] = useState('');
    const { userData } = useUser();

    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/participants`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                });
                setParticipants(response.data);
                setFilteredParticipants(response.data);
            } catch (error) {
                console.error('Error fetching participants:', error);
                toast.error(`Error fetching participants: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };
        fetchParticipants();
    }, []);

    useEffect(() => {
        const lowercasedRewardFilterText = rewardFilterText.toLowerCase();
        const lowercasedAnswerFilterText = answerFilterText.toLowerCase();

        const filtered = participants.filter(participant => {
            const hasRewardMatch = participant.invoice && participant.invoice.products && parseProducts(participant.invoice.products).some(product =>
                product.reward && product.reward.name.toLowerCase().includes(lowercasedRewardFilterText)
            );

            const hasAnswerMatch = participant.invoice ? participant.invoice.is_correct.toLowerCase().includes(lowercasedAnswerFilterText) : false;

            return (lowercasedRewardFilterText === '' || hasRewardMatch) && (lowercasedAnswerFilterText === '' || hasAnswerMatch);
        });

        setFilteredParticipants(filtered);
    }, [rewardFilterText, answerFilterText, participants]);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    const parseProducts = (products) => {
        if (typeof products === 'string') {
            try {
                return JSON.parse(products);
            } catch (error) {
                console.error('Error parsing products JSON:', error);
                return [];
            }
        }
        return products || [];
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.text('Participants List', 14, 16);
        doc.autoTable({
            startY: 22,
            head: [['Username', 'Invoice ID', 'Answer', 'Total Amount', 'User Number', 'Products & Rewards']],
            body: filteredParticipants.map(participant => [
                participant.invoice && participant.invoice.user ? participant.invoice.user.name : 'N/A',
                participant.invoice ? participant.invoice.invoice_id : 'N/A',
                participant.invoice ? participant.invoice.is_correct : 'N/A',
                Number(participant.total_amount).toFixed(2),
                participant.userNumber,
                participant.invoice && participant.invoice.products
                    ? parseProducts(participant.invoice.products).map(product => product.reward ? product.reward.name : 'N/A').join(', ')
                    : 'No products or invalid products data'
            ]),
            margin: { top: 22 },
        });
        doc.save('participants-list.pdf');
    };

    const downloadCSV = () => {
        const csvRows = [];
        csvRows.push(['Username', 'Invoice ID', 'Answer', 'Total Amount', 'User Number', 'Products & Rewards'].join(','));

        filteredParticipants.forEach(participant => {
            const row = [
                participant.invoice && participant.invoice.user ? participant.invoice.user.name : 'N/A',
                participant.invoice ? participant.invoice.invoice_id : 'N/A',
                participant.invoice ? participant.invoice.is_correct : 'N/A',
                Number(participant.total_amount).toFixed(2),
                participant.userNumber,
                participant.invoice && participant.invoice.products
                    ? parseProducts(participant.invoice.products).map(product => product.reward ? product.reward.name : 'N/A').join(', ')
                    : 'No products or invalid products data'
            ].join(',');
            csvRows.push(row);
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'participants-list.csv';
        link.click();
    };

    if (loading) return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content">
                        <div className="d-flex justify-content-center">
                            <Loader />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Participants List</h1>
                        </div>
                        <div className='d-flex justify-content-center mb-3'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Seacrh by reward"
                                value={rewardFilterText}
                                onChange={(e) => setRewardFilterText(e.target.value)}
                            />
                            <input
                                type="text"
                                className="form-control ms-2"
                                placeholder="Seacrh by answer"
                                value={answerFilterText}
                                onChange={(e) => setAnswerFilterText(e.target.value)}
                            />
                            <button className="btn btn-primary ms-2" onClick={downloadPDF}>PDF</button>
                      
                        </div>

                        <div className="table-responsive">
                            {filteredParticipants.length > 0 ? (
                                <table className="table table-hover table-striped table-bordered shadow-sm rounded">
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Username</th>
                                            <th>Invoice ID</th>
                                            <th>Answer</th>
                                            <th>Total Amount</th>
                                            <th>User Number</th>
                                            <th>Products & Rewards</th>
                                            <th>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredParticipants.map(participant => (
                                            <tr key={participant.id}>
                                                <td>{participant.invoice && participant.invoice.user ? participant.invoice.user.name : 'N/A'}</td>
                                                <td>{participant.invoice ? participant.invoice.invoice_id : 'N/A'}</td>
                                                <td>{participant.invoice ? participant.invoice.is_correct : 'N/A'}</td>
                                                <td>{Number(participant.total_amount).toFixed(2)}</td>
                                                <td>{participant.userNumber}</td>
                                                <td>
                                                    {participant.invoice && participant.invoice.products ? (
                                                        parseProducts(participant.invoice.products).map((product, index) => (
                                                            <div className='d-flex justify-content-between' key={index}>
                                                                {product.reward && (
                                                                    <div>
                                                                        <p>{product.reward.name}</p>
                                                                    </div>
                                                                )}
                                                                <hr />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No products or invalid products data</p>
                                                    )}
                                                </td>
                                                <td>
                                                    <Link to={`/dashboard/participants/${participant.id}`} className="btn btn-info btn-sm">
                                                        View Details
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center">
                                    <p className="fs-5 text-danger">No participants found</p>
                                    <table className="table table-hover table-striped table-bordered shadow-sm rounded">
                                        <thead className="table-dark">
                                            <tr>
                                                <th>Invoice ID</th>
                                                <th>Total Amount</th>
                                                <th>User Number</th>
                                                <th>Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" className="text-center">No data available</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    );
};

export default ParticipantsList;
