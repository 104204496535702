import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import 'flipclock/dist/flipclock.css';
import FlipClock from 'flipclock';
import '../../css/Styles.css';
import Loader from '../../Components/LoaderComponent';
import { BASE_URL } from '../../../Services';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useUser } from '../../../Services/UserContext.js';
import { useNavigate } from 'react-router-dom';

const GameScreen = () => {
    const { userData } = useUser()
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [result, setResult] = useState('');
    const [activeOption, setActiveOption] = useState(null);
    const clockRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/questions`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });
                if (response.data.status === 'Success') {
                    setQuestions(response.data.data); // Use the 'data' field from the response
                    selectRandomQuestion(response.data.data); // Use the 'data' field from the response
                } else {
                    console.error('Failed to fetch questions.');
                }
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };

        fetchQuestions();
    }, []);

    useEffect(() => {
        if (clockRef.current) {
            const clock = new FlipClock(clockRef.current, {
                clockFace: 'HourlyCounter',
                autoStart: false,
                callbacks: {
                    stop: function () {
                        window.location.href = `${BASE_URL}/questions`;
                    }
                }
            });
            clock.setTime(180); // Time in seconds
            clock.setCountdown(true);
            clock.start();
        }
    }, [clockRef]);

    const selectRandomQuestion = (questionsList) => {
        if (questionsList.length > 0) {
            const randomIndex = Math.floor(Math.random() * questionsList.length);
            setCurrentQuestion(questionsList[randomIndex]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedOption === '') {
            toast.error("Please select an option");
        } else {

            if (currentQuestion) {
                const isCorrect = currentQuestion.options.some(option => option.optionTitle === selectedOption && option.correctOption === 'Y');
                setResult(isCorrect ? 'Correct!' : 'Wrong answer!');

                try {
                    // Update cart with correctness
                    await axios.post(
                        `${BASE_URL}/cart/update-is-correct`,
                        { is_correct: isCorrect ? 'yes' : 'no' },
                        { headers: { Authorization: `Bearer ${userData?.token}` } }
                    );

                    // Submit cart data to invoice
                    await axios.post(
                        `${BASE_URL}/cart/addInvoice`,
                        { is_correct: isCorrect ? 'yes' : 'no' },
                        { headers: { Authorization: `Bearer ${userData?.token}` } }
                    ).then(response => {
                        const invoiceId = response?.data?.invoice?.invoice_id;
                        if (invoiceId) {
                            window.location.href = `https://www.dukan.pk/enaam?invoice_id=${invoiceId}`;
                        } else {
                            console.error('Invoice ID not found in response:', response.data);
                        }
                    }).catch(error => {
                        console.error('Error submitting cart data:', error);
                    });

                    setTimeout(() => {
                        selectRandomQuestion(questions);
                        setSelectedOption('');
                        setResult('');
                        setActiveOption(null);
                    }, 2000);
                } catch (error) {
                    console.error('Error processing the request:', error);
                }
            }
        }
    };

    const handleOptionClick = (optionValue) => {
        setSelectedOption(optionValue);
        setActiveOption(optionValue);
    };


    if (!currentQuestion) return <Loader />;
    return (
        <div className="App">
            <ToastContainer />
            <Header />
            <div className="container-fluid col-lg-10 mt-5 p-5">
                <div style={{height:"10vh"}}/>
                <div className="row">
                    <div className="col-md-4 question-section">
                        <div className="clock" ref={clockRef} style={{ marginTop: '-15px' }}></div>
                        <div className="question" style={{ textAlign: "center" }}>
                            <p style={{ width: "80%" , fontWeight:"bold"}}>{currentQuestion.title}</p>

                        </div>
                        <div className="question-wrapper">
                            <p className="text-center">Click to select your answer</p>
                            <button type="button" className="btn btn-primary w-100" onClick={handleSubmit}>CONFIRM</button>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="game-wrapper" style={{ padding: 20 }}>
                            <div className="row justify-content-center" style={{ height: "100%" }}>
                                {currentQuestion.options.map((option, index) => (
                                    <div
                                        className="col-sm-6 col-md-5 col-lg-5 mb-3"
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div
                                            className={`glow option-block ${selectedOption === option.optionTitle ? 'selected' : ''
                                                }`}
                                            onClick={() => handleOptionClick(option.optionTitle)}
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                borderRadius: '8px',
                                                backgroundColor: selectedOption === option.optionTitle ? '#007bff' : '#f8f9fa',
                                                color: selectedOption === option.optionTitle ? '#fff' : '#000',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                transition: 'background-color 0.3s ease, border-color 0.3s ease',
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                id={`option${index + 1}-${currentQuestion.id}`}
                                                name={`question-${currentQuestion.id}`}
                                                value={option.optionTitle}
                                                checked={selectedOption === option.optionTitle}
                                                onChange={() => setSelectedOption(option.optionTitle)}
                                                style={{ display: 'none' }} // Hide the radio button
                                            />
                                            <label
                                                htmlFor={`option${index + 1}-${currentQuestion.id}`}
                                                style={{
                                                    width: '100%',
                                                    alignItems: "center",
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold',
                                                    fontSize: '1rem',
                                                    padding: '10px',
                                                }}
                                            >
                                                {index + 1 + ".   " + option.optionTitle}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default GameScreen;
