

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../../../Services/UserContext';
import { BASE_URL } from '../../../../Services';

const CartIcon = () => {
    const { cartQuantity ,getCartQuantity} = useUser()

    useEffect(() => {
        getCartQuantity()
    }, []);

    return (
        <Link  className="cart-icon position-relative d-inline-flex align-items-center">
            <i className="bi bi-cart3 text-white" style={{ fontSize: '1.5rem', color: '#000' }}></i>
            {cartQuantity > 0 && (
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ">
                    {cartQuantity}
                    <span className="visually-hidden">items in cart</span>
                </span>
            )}
        </Link>
    );
};

export default CartIcon;
