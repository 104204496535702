import React from 'react';
import FooterAppDownload from './FooterAppDownload';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

const FooterSocialLinks = () => {
  return (
    <div className="col-lg-5 col-md-6 col-sm-12 footer-list-29 footer-4 mt-lg-0 mt-4 d-flex flex-column align-items-center align-items-lg-end">
      <div className="social-wrap w-100 d-flex justify-content-center justify-content-lg-end ">
        <div className="main-social-footer-29 mt-4 d-flex justify-content-center justify-content-lg-start">
          <a href="https://www.facebook.com/profile.php?id=100091683138802&mibextid=ZbWKwL" className="facebook mx-2 mb-2" style={{ fontSize: '1.5rem' }}>
            <span className="fab fa-facebook-f"></span>
          </a>
          <a href="https://twitter.com/enaampkofficial" className="twitter mx-2 mb-2" style={{ fontSize: '1.5rem' }}>
            <span className="fab fa-twitter"></span>
          </a>
          <a href="https://www.tiktok.com/@enaampk" className="tiktok mx-2 mb-2" style={{ fontSize: '1.5rem' }}>
            <span className="fab fa-tiktok"></span>
          </a>
          <a href="https://instagram.com/enaampk.official?igshid=MzRlODBiNWFlZA==" className="instagram mx-2 mb-2" style={{ fontSize: '1.5rem' }}>
            <span className="fab fa-instagram"></span>
          </a>
          <a href="https://www.youtube.com/@enaampk" className="youtube mx-2 mb-2" style={{ fontSize: '1.5rem' }}>
            <span className="fab fa-youtube"></span>
          </a>
          <a href="https://wa.me/+923211118392" className="whatsapp mx-2 mb-2" style={{ fontSize: '1.5rem' }}>
            <span className="fab fa-whatsapp"></span>
          </a>
        </div>
      </div>
      <FooterAppDownload className="w-100 d-flex justify-content-center justify-content-lg-end" />
    </div>
  );
};

export default FooterSocialLinks;
