import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import "../../css/ProductList.css";
import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import Loader from '../../Components/LoaderComponent'; 
import { BASE_URL } from '../../../Services';

const ProductDetails = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/products/${id}`);
                setProduct(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching product details:', error);
                setLoading(false);
            }
        };

        fetchProductDetails();
    }, [id]);

    const addProductToCart = async () => {
        try {
            if (!localStorage.getItem('token')) {
                alert('You need to login first');
                navigate('/dashboard/login');
                return;
            }
            const response = await axios.post(`${BASE_URL}/cart/add`, {
                product_id: product.id,
                quantity: 1,
                price: product.price
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            alert('Product added to cart successfully');
            navigate('/cart');
        } catch (error) {
            console.error('Error adding product to cart:', error);
            if (error.response) {
                if (error.response.status === 401) {
                    alert('You need to login first');
                    navigate('/dashboard/login');
                } else {
                    alert(`Failed to add product to cart: ${error.response.data.message}`);
                }
            } else if (error.request) {
                alert('No response from the server. Please try again later.');
            } else {
                alert(`Error: ${error.message}`);
            }
        }
    };

    if (loading) return <Loader />; 
    if (!product) return <p>Product not found.</p>;

    const percentage = (product.sold / product.quantity) * 100;
    let progressBarClass;
    let buttonStyle = {};
    let buttonText = 'Add to Cart';
    let drawDateSection = null;

    if (percentage < 70) {
        progressBarClass = 'bg-success'; 
    } else if (percentage >= 70 && percentage < 100) {
        progressBarClass = 'bg-warning'; 
    } else {
        progressBarClass = 'bg-danger'; 
        buttonStyle = { pointerEvents: 'none', opacity: 0.6 };
        buttonText = 'Entries Completed';
    }

    if (percentage === 100 && product.draw_date) {
        drawDateSection = (
            <div>
                <img src="/path/to/calendar.png" alt="" />
                <a href="">
                    Max draw date: <br />
                    {new Intl.DateTimeFormat('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    }).format(new Date(product.draw_date))}
                </a>
            </div>
        );
    }

    return (
        <div className="container-fluid mt-5 p-0">
        
            <Header />
        
            <div className="container-fluid mt-5 mb-5 col-lg-11 col-11 p-2 product-details ">
             <br></br>
                <div className="row mt-5">
                    <div className="col-md-8 col-lg-9 mt-5">
                        <div className="sub-info glow">
                            <div className='p-2 mt-2'>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                               
                                <h1 style={{ textAlign: 'left' , fontWeight: '' ,}}>
                                    <span className='fw-bold'>Reward : </span>
                                     {product.reward.name}</h1>
                                <div>
                                    {drawDateSection}
                                    <div className="product-detail-state d-none d-lg-block d-md-block" style={{ width: '150px' }}>
                                        <h6 className="mb-0 card-heading">
                                            <p>{product.sold} Entries out of {product.quantity}</p>
                                        </h6>
                                        <div className="progress">
                                            <div className={progressBarClass} role="progressbar" style={{ width: `${percentage}%` }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div  className="row chunking-wrapper mt-3">
                                <div className="col-md-6">
                                    <div className="detail-chunk ">
                                        <div className="detail-img">
                                            <img src={product.image} style={{ borderRadius: "20px" }} alt={product.name} />
                                        </div>
                                        <h4  style={{ marginTop: "10px" }}>Spend PKR: {product.price}</h4>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="detail-chunk">
                                        <div className="detail-img">
                                            <img src={product.reward.image} style={{ borderRadius: "20px" }} alt={product.reward.name} />
                                        </div>
                                        <h4 style={{ marginTop: "10px" }}>Get a chance to win:</h4>
                                        <p style={{lineHeight:'1'}} className='fw-bold'>{product.reward.name}</p>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="product-description">
                                <h4 style={{ textAlign: 'left' ,color:'#007bff', fontWeight: 'bold' }}>Prize Details</h4>
                                <p className='d-flex justify-content-start' style={{fontSize:'15px'}}>{product.reward.description}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 mt-5">
                        <div className="price-section">
                            <div>
                                <h2 className="price-heading">Price</h2>
                                <span className="inclusive">Inclusive of VAT</span>
                            </div>
                            <div className="value-price">RS. {product.price}</div>
                        </div>
                        <div className="cart-btn-wrap">
                            <a className="btn cart-btn" style={{ color: 'white', ...buttonStyle }} onClick={addProductToCart}>
                                {buttonText}
                            </a>
                        </div>
                        <div className="compaign-btn">
                            <button onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u=https://example.com/', 'facebook-share-dialog', 'width=800,height=600')}>
                                <img src="/path/to/compaign-btn.png" alt="" style={{ cursor: 'pointer', maxWidth: '100%' }} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ProductDetails;
