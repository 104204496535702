import React from 'react';
import FooterCompanyLinks from './FooterCompanyLinks';
import FooterSupportLinks from './FooterSupportLinks';
import FooterSocialLinks from './FooterSocialLinks';
import CartIcon from '../../pages/HomeScreen/Components/CartIcon';
import { useNavigate } from 'react-router-dom';

const FooterTop = ({profile}) => {
  const navigate = useNavigate()
  return (
    <div className="row footer-top-29">
      {profile?.profile===false? <div/> :
      <div className="shipping-card text-start mr-5" >
        <a onClick={()=>{ navigate('/cart');}}  className="shopping-cart-button">
          <span id="cartQuantity" className="cart-quantity position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger"></span>
          <img src="assets/images/cart.png" alt="" style={{ maxWidth: '100%' }} />
        <span className="hide-on-mobile align-items-center d-flex " style={{height:0,padding:5,paddingBottom:10,color:"#fff", whiteSpace: 'nowrap'}}>
            <CartIcon />
             -Shopping cart
          </span>
        </a>
      </div> }
      <FooterCompanyLinks />
      <FooterSupportLinks />
      <div className="col-lg-3"></div>
      <FooterSocialLinks />
    </div>
  );
}

export default FooterTop;
