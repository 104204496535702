import React, { useState } from 'react';
import axios from 'axios';
import { useUser } from '../Services/UserContext';
import { useAuth } from '../Services/AuthContext';
import { BASE_URL } from '../Services';

function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);

    const { signUp } = useAuth();

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await signUp({
                name,
                email,
                password,
                password_confirmation: passwordConfirmation,
                phone
            });
            setMessage(response.message);
            setStep(2);
        } catch (error) {
            setError('Registration failed. Please check your input and try again.');
            setMessage('');
            console.error(error);
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BASE_URL}/verify-otp`, {
                phone,
                otp
            });
            localStorage.setItem('token', response.data.token);
            setMessage('Registration successful!');
            setError('');
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            setError('OTP verification failed. Please try again.');
            setMessage('');
            console.error(error);
        }
        window.location.reload();

    };

    return (
        <div className="d-flex justify-content-center align-items-center ">
            {step === 1 && (
                <div className=" p-1" style={{  width: '400px' }}>
                    <div className="text-center mb-4">
                        <div className="mb-3">
                            <img
                                src={require('../UserSide/images/logo.png')}// Replace with your icon path or use an appropriate image/icon tag
                                alt="Register Icon"
                                style={{ width: '120px' , height: '50px'}}
                            />
                        </div>
                        <h3 className="mb-3" style={{ color: '#35B5FF' }}>Register Now</h3>
                    </div>
                    <form onSubmit={handleRegister}>
                        <div className="mb-3">
                            <input
                                type="text"
                                id="name"
                                className="form-control"
                                placeholder="Enter your Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                style={{
                                    borderRadius: '20px',
                                    borderColor: '#E6E6E6',
                                    padding: '10px',
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="email"
                                id="email"
                                className="form-control"
                                placeholder="Enter your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                style={{
                                    borderRadius: '20px',
                                    borderColor: '#E6E6E6',
                                    padding: '10px',
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="password"
                                id="password"
                                className="form-control"
                                placeholder="Enter your Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                style={{
                                    borderRadius: '20px',
                                    borderColor: '#E6E6E6',
                                    padding: '10px',
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="password"
                                id="passwordConfirmation"
                                className="form-control"
                                placeholder="Confirm your Password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                required
                                style={{
                                    borderRadius: '20px',
                                    borderColor: '#E6E6E6',
                                    padding: '10px',
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                id="phone"
                                className="form-control"
                                placeholder="Enter your Phone Number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                style={{
                                    borderRadius: '20px',
                                    borderColor: '#E6E6E6',
                                    padding: '10px',
                                }}
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary w-100"
                            style={{
                                backgroundColor: '#35B5FF',
                                borderColor: '#35B5FF',
                                borderRadius: '20px',
                                padding: '10px',
                                fontSize: '16px',
                            }}
                        >
                            Register
                        </button>
                    </form>
                    {message && <p className="text-success mt-3 text-center">{message}</p>}
                    {error && <p className="text-danger mt-3 text-center">{error}</p>}
                </div>
            )}
            {step === 2 && (
                <div className=" p-5" style={{ borderRadius: '15px', width: '400px' }}>
                    <div className="text-center mb-4">
                        <div className="mb-3">
                            <img
                                src={require('../UserSide/images/logo.png')}
                                alt="OTP Icon"
                                style={{ width: '120px' , height: '50px'}}
                            />
                        </div>
                        <h3 className="mb-3" style={{ color: '#35B5FF' }}>Verify OTP</h3>
                    </div>
                    <form onSubmit={handleVerifyOtp}>
                        <div className="mb-3">
                            <input
                                type="text"
                                id="otp"
                                className="form-control"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                                style={{
                                    borderRadius: '20px',
                                    borderColor: '#E6E6E6',
                                    padding: '10px',
                                }}
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary w-100"
                            style={{
                                backgroundColor: '#35B5FF',
                                borderColor: '#35B5FF',
                                borderRadius: '20px',
                                padding: '10px',
                                fontSize: '16px',
                            }}
                        >
                            Verify OTP
                        </button>
                    </form>
                    {message && <p className="text-success mt-3 text-center">{message}</p>}
                    {error && <p className="text-danger mt-3 text-center">{error}</p>}
                </div>
            )}
        </div>
    );
}

export default Register;
