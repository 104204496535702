import HomeBanner from "./Components/HomeBannerComponent";
import WinnersCarousel from "./Components/WinnersComponent";
import RecentLuckyDraws from "./Components/VideosSectionComponent";
import ProductsListComponent from "./Components/ProductsListComponent";

import '../../css/App.css';
import InsightSection from "./Components/InsightSectionComponent";
import Footer from "../../Components/FooterCompnent";
import { useEffect } from "react";
import { useUser } from "../../../Services/UserContext";

function App() {
  const { getCartQuantity } = useUser();
  useEffect(()=> {
    // getCartQuantity()
  })
  return (
    <div className="App">
      <HomeBanner />
      {/* <WinnersCarousel/>
         */}
      <RecentLuckyDraws />
      <InsightSection />
      <ProductsListComponent />
      <Footer />
    </div>
  );
}

export default App;
