
import React, { useState } from 'react';
import './Header.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Services/AuthContext.js';

function Header({ isOpen , screenName }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { logout } = useAuth(); 
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = ( ) => {
        logout();
        navigate('/dashboard/login');
    }

    return (
        <div style={{width: '100%', marginLeft: '0px'}} className={`header ${isOpen ? 'header-expanded  ' : 'header-collapsed'}`}>
            <h1 className='text-white ms-4' >{screenName} </h1>
            <div className="profile-menu" onClick={toggleDropdown}>
                <div style={{margin: '5px',}} className="profile-icon ">
                    <img   src={require('../../UserSide/images/user.png')}  alt="User Profile" style={{height:"30px",width:"30px"}} />
                </div>
                <div style={{marginLeft: '-150px',}} className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                    <a href="/profileScreen">Profile</a>
                    <a onClick={handleLogout}>Logout</a>
                </div>
            </div>
        </div>
    );
}

export default Header;
